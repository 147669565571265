import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const HaveAccountTypo = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
`;

export const FooterGrid = styled(Grid)`
  margin: 3vw 0;
  justify-content: center;

  @media (min-width: 992px) {
    margin: 2vw 0;
  }
`;
