import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ShowIcon, HideIcon } from '../../assets/svgs';

export const VisIcon = styled(Visibility)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const VisOffIcon = styled(VisibilityOff)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;
